.mm {

	&-menu {
		z-index: 2;
		top: 108px;

		--mm-lineheight: #{$global-control-height};
		--mm-listitem-size: #{$global-control-large-height};
		--mm-navbar-size: #{$global-control-height};
		--mm-toggle-size: #{$global-control-small-height};
		--mm-translate-multiplier: 0;

		&[inert='true'] {
			pointer-events: none;
			cursor: default;
		}

		&[inert='true'],
		&[inert='true'] * {
			user-select: none;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
		}

		&--theme-light {
			--mm-size: 100%;
			--mm-min-size: 256px;
			--mm-max-size: 2048px;
			--mm-color-background: #{$primary};
			--mm-color-border: #{rgba($white, 0.05)};
			--mm-color-icon: #{$white};
			--mm-color-text: #{$white};
			--mm-color-text-dimmed: #{rgba($white, 0.7)};
			--mm-color-background-highlight: #{rgba($white, 0.05)};
			--mm-color-background-emphasis: #{rgba($global-muted-background, 0.4)};
			--mm-color-focusring: #{$white};
		}

		@media (min-width: $breakpoint-small) {
			top: 132px;
		}
	}

	&-listitem {
		text-transform: uppercase;

		&::after {
			left: 0;
		}
	}

	&-wrapper {

		.header {

			.uk-navbar-toggle {
				min-width: 44px;
				min-height: 44px;
				position: relative;

				span,
				span::before,
				span::after {
					position: absolute;
					height: 2px;
					width: 20px;
					background-color: $white;
					transition: $transition;
				}

				span {

					&::before,
					&::after {
						content: '';
						left: 50%;
						transform: translateX(-50%);
					}

					&::before {
						top: -6px;
					}

					&::after {
						top: 6px;
					}
				}
			}
		}

		&--opened {

			.mm-page {
				max-height: 100vh;
				overflow: hidden;
			}

			.header {

				.uk-navbar-toggle {

					span {
						background-color: transparent;

						&::before {
							transform: rotate(45deg) translate(-2.5px, 12px);
						}

						&::after {
							transform: rotate(-45deg) translate(-3.5px, -11px);
						}
					}
				}
			}
		}

		&__blocker {
			background: 0 0;
			opacity: 1;
			z-index: 1;
		}
	}

	&-panels>div:first-child>.mm-navbar {
		display: none;
	}
}